<template>      
    <VaButton @click="showModal = true" 
              preset="secondary"  
              border-color="primary" 
              class="button-margin">Добавить тип промокода</VaButton>
    
    <VaModal 
      ref="modal" 
      v-model="showModal" 
      class="modal-crud"
      size="small"
      title="Добавить тип промокода"
      @ok="addPromocodeType"
      @cancel="closeModal"
      :no-ok-close="true" 
      >
      <VaInput        
        class="ml-3 form-element"
        label="Название типа промокода"        
        v-model="name"
      />   
      <VaInput        
        class="ml-3 form-element"
        label="Описание"      
        v-model="description"  
      />       
      <div v-if="hasErrors" class="alert alert-danger mt-3">
        <div v-for="(error, key) in errors" :key="key">{{ error }}</div>
      </div>   
    </VaModal>  
</template>

<script>
import http from '@/services/http-common';

export default {
  emits: ['promocode-type-added'],
  data() {
    return {
      showModal: false,
      name: '',
      description: '',      
      errors: {},
    };
  },  
  methods: {    
    closeModal()
    {
      this.showModal = false;
    },     
    async addPromocodeType() {
      const promocodetype = {
          name: this.name,
          description: this.description,         
      };      
      if (!this.validate(promocodetype)) {
        this.showModal = true;
        return; 
      }
      try
      {
        await http.post('/promocodes/types', promocodetype);  
      }
      catch (error)
      {
        console.error('Ошибка при добавлении типа промокода:', error);
        if (error.response && error.response.status === 400 && error.response.data) {         
          this.errors.addpromocodetyperequest = "Ошибка добавления типа промокода. Данный тип существует. Пожалуйста введите уникальное название.";          
        } else {          
          this.errors.addpromocodetyperequest = 'Произошла ошибка при добавлении типа промокода. Пожалуйста, попробуйте еще раз или обратитесь к администратору.';
        }
        this.showModal = true;          
        return; 
      }

      this.$emit('promocode-type-added');
    },
    validate(promocodetype) {
      this.errors = {}; 

      if (!promocodetype.name || promocodetype.name.length > 100 || promocodetype.name.length <= 3) {
        this.errors.name = "Некорректное имя типа промокода. Длина имени должна быть более 3 символов.";
      }      
      if (!promocodetype.description || promocodetype.description.length > 300 || promocodetype.description.length <= 3) {        
        this.errors.description = "Некорректное описание типа промокода. Длина описания должна быть более 3 символов и менее 300 символов.";
      }          

      return Object.keys(this.errors).length === 0;
    }
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    }
  }
};
</script>
