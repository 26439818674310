import axios from 'axios';
import { API_BASE_URL } from '@/config/apiConfig';
import router from '@/router';
import store from '@/store';

const http = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        "Content-type": "application/json"
    }
});

http.interceptors.request.use(
    config => {
        const token = store.getters.accessToken;
        const expiry = new Date(store.getters.expiry);

        const currentDate = new Date();
        
        if (isNaN(expiry) || expiry < currentDate)
        {
            store.dispatch('logout');
            router.push('/login');
        }

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    response => response,
    error => {
        return handleError(error);
    }
);

function handleError(error) {
    if (!error.response || error.response.status !== 401) {
        return Promise.reject(error);
    }

    const originalRequest = error.config;
    originalRequest._retry = true;

    const refreshToken = store.state.auth;

    if (refreshToken) {
        return refreshTokenPromise(refreshToken, originalRequest);
    } else {
        return handleRefreshError(error);
    }
}

function refreshTokenPromise(refreshToken, originalRequest) {
    return http.post('/authorize/refresh-token', refreshToken)
        .then(response => {
            store.commit('setAuth', response.data);
            originalRequest.headers['Authorization'] = `Bearer ${response.data.accessToken}`;
            return http(originalRequest);
        })
        .catch(refreshError => handleRefreshError(refreshError));
}

function handleRefreshError(refreshError) {
    store.dispatch('logout');
    router.push('/login');
    return Promise.reject(refreshError);
}

export default http;
