<template>      
    <VaButton @click="showModal = true" 
              preset="secondary"  
              border-color="primary" 
              class="button-margin">Добавить промокод</VaButton>
    
    <VaModal 
      ref="modal" 
      v-model="showModal" 
      class="modal-crud"
      size="small"
      title="Добавить промокод"
      @ok="addPromocode"
      @cancel="closeModal"
      :no-ok-close="true" 
      >

      <VaInput        
          class="ml-3 form-element"
          label="Название промокода"        
          v-model="code"
      />  
      
      <VaDateInput
        class="ml-3 form-element calendar-width"
        label="Срок действия"
        v-model="expiry"
        clearable
      />

      <VaSelect
        class="ml-3 form-element"
        label="Тип промокода"
        v-model="promoCodeTypeID"
        :options="promoCodeTypes">
      </VaSelect>
        
      <VaCollapse          
        class="min-w-96 min-w-95-percent"
        header="Меню генерации промокода"
        icon="sync"     
      >
        <VaInput
          class="ml-3 form-element gen-length-input"
          placeholder="Длина"          
          v-model="length"          
        />          

        <VaCheckbox           
          class="ml-3 form-element"
          label="Cпециальные символы"
          v-model="includeSpecialChars" />
        
        <VaCheckbox         
          class="ml-3 form-element"
          label="Заглавные буквы" 
          v-model="uppercase"/> 

        <div class="flex-div">
          <VaButton @click="generatePromoCode" 
                    preset="secondary"
                    hover-behavior="opacity"
                    :hover-opacity="0.4"
                    class="mr-6 mb-2 align-right"
          >
            Сгенерировать
          </VaButton>
        </div>
      </VaCollapse>      

      <div v-if="hasErrors" class="alert alert-danger mt-3">
        <div v-for="(error, key) in errors" :key="key">{{ error }}</div>
      </div>  

    </VaModal>  
</template>

<script>
import http from '@/services/http-common';

export default {
  emits: ['promocode-added'],
  data() {
    return {   
      includeSpecialChars:false,
      uppercase: false,
      length: null,         
      showModal: false,
      code: '',
      expiry: new Date(),
      promoCodeTypeID: null,
      promoCodeTypes: [],
      promoCodeTypesOptions: [],
      errors: {},
    };
  },  
  created() {
    this.loadPromoCodeTypesOptions();
  },
  methods: {    
    async loadPromoCodeTypesOptions() {
        try {
            const response = await http.get('/promocodes/types')
            this.promoCodeTypesOptions = response.data
                .filter(promoCodeType => promoCodeType.state === true)
                .map(promoCodeType => ({
                    text: promoCodeType.name,
                    value: promoCodeType.id
                }));           
            this.promoCodeTypes = this.promoCodeTypesOptions.map(option => option.text);
            
            this.promoCodeTypeID = this.promoCodeTypesOptions.length > 0 ? this.promoCodeTypesOptions[0].text : null;
                        
        } catch (error) {
            console.error('Ошибка при загрузке списка типов промокодов', error);
        }
    }
    ,
    closeModal()
    {
      this.showModal = false;
    },     
    async addPromocode() {
      const promocode = {
          code: this.code,
          expiry: this.expiry,    
          promoCodeTypeID: this.findPromoCodeTypeIdByText(this.promoCodeTypeID)
      };      
      if (!this.validate(promocode)) {
        this.showModal = true;
        return; 
      }
      try
      {
        await http.post('/promocodes', promocode);  
      }
      catch (error)
      {
        console.error('Ошибка при добавлении промокода:', error);
        if (error.response && error.response.status === 400 && error.response.data) {         
          this.errors.addpromocoderequest = "Ошибка добавления промокода. Данный промокод уже существует. Пожалуйста введите уникальное имя.";          
        } else {          
          this.errors.addpromocoderequest = 'Произошла ошибка при добавлении промокода. Пожалуйста, попробуйте еще раз или обратитесь к администратору.';
        }
        this.showModal = true;          
        return; 
      }

      this.$emit('promocode-added');
    },
    validate(promocode) {
      this.errors = {}; 

      if (!promocode.code || promocode.code.length > 100 || promocode.code.length <= 3) {
        this.errors.name = "Некорректное имя промокода. Длина имени должна быть более 4 символов.";
      }            
      if (!promocode.expiry || promocode.expiry <= new Date().setHours(0,0,0,0)) {        
        this.errors.description = "Некорректная дата истечения промокода. Дата истечения должна быть не ранее сегодняшнего дня";
      }          

      return Object.keys(this.errors).length === 0;
    },
    findPromoCodeTypeIdByText(promoCodeTypeText) {     
      const promoCodeType = this.promoCodeTypesOptions.find(option => option.text === promoCodeTypeText);           
      return promoCodeType ? promoCodeType.value : null;
    }, 
    async generatePromoCode() {

      const promoCodeGenerationSettings = {
        length: parseInt(this.length),
        includeSpecialChars: this.includeSpecialChars,
        uppercase: this.uppercase
      };
      
      if (!this.settingsValidation(promoCodeGenerationSettings))
      {
        return;
      }

      try
      {
        let result = await http.post('/promocodes/management/generate', promoCodeGenerationSettings);  
        this.code = result.data.code.toString();
        
      }
      catch (error)
      {
        console.log(error)
      }      
    },
    settingsValidation(settings)
    {
      this.errors = {}; 

      if (!settings.length || settings.length < 4 || settings.length> 40 )
      {
        this.errors.length = "Некорректное задана длина генерации. Длина генерации должна быть более 3 и менее 40 символов";
      }

      return Object.keys(this.errors).length === 0;
    }
  },
  computed: {
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },    
  },
  
};
</script>

<style>

    .calendar-width {
        width: 30cap;
    }

    .input-with-button {
      display: flex; 
      align-items: center;
    }

    .gen-length-input {
      width: 70px; 
    }

    .min-w-95-percent {
      width: 95%;
      margin-left: 10px;
    }

    .flex-div {
      display: flex;
    }
    
    .align-right {
      margin-left: auto; 
      margin-right: 8%;
    }

</style>