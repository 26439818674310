<template>

    <VaInput
      v-model="filter"
      title="Search"
      placeholder="Поиск промокода"      
      clearable
      class="mr-3 grow-0 basis-24"
    />  

    <ModalAddPromoCode @promocode-added="fetchData" />

    <VaDataTable      
      :items="transformedItems"
      :filter="filter"
      :filter-method="customFilterMethod"
      :columns="columns"
      style="height: 80vh"  
      virtual-scroller   
    >
      <template #cell(actions)="{ rowIndex }">
        <VaButton
          preset="plain"
          icon="edit"
          @click="openModalToEditItemById(rowIndex)"
        />
        <VaButton
          preset="plain"
          icon="delete"
          class="ml-3"
          @click="deleteItemById(rowIndex)"
        />
        <ModalAddSystems :codeId="getIdByRowIndex(rowIndex)"></ModalAddSystems>
        
      </template>
    </VaDataTable>
  
    <VaModal
      class="modal-crud"
      :model-value="!!editedItem"
      title="Редактирование промокода"
      size="small"
      @ok="editItem"
      @cancel="resetEditedItem"
    >
    <template v-for="key in Object.keys(editedItem)" :key="key">
      <template v-if="key === 'id'"> 
        <VaInput
          v-model="editedItem[key]"
          class="ml-3 form-element"
          label="Id пользователя"
          disabled
        />      
      </template>    
      <template v-else-if="key === 'code'">
        <VaInput
          v-model="editedItem.code" 
          class="ml-3 form-element"
          label="Промокод"
        />
      </template>      
      <template v-else-if="key === 'expiry'">
        <VaDateInput
          v-model="editedItem.expiry" 
          class="ml-3 form-element"
          label="Срок действия"
        />
      </template>      
      <template v-else-if="key === 'promoCodeType'">
        <VaSelect
          v-model="editedItem.promoCodeType" 
          class="ml-3 form-element"
          label="Тип промокода"
          :options="promoCodeTypes"          
        />
      </template>   
      <template v-else-if="key === 'username'">
        <VaInput
          v-model="editedItem.username" 
          class="ml-3 form-element"
          label="Пользователь"
          disabled
        />
      </template>         
    </template> 
    <div v-if="hasErrors" class="alert alert-danger mt-3">
      <div v-for="(error, key) in errors" :key="key">{{ error }}</div>
    </div>
    <div v-if="successMessage" class="alert alert-success mt-3">
        {{ successMessage }}
    </div>
    </VaModal>

  </template>
  
  <script>
  import { defineComponent } from "vue";
  import http from '@/services/http-common';
  import ModalAddPromoCode from '@/components/ModalAddPromoCode.vue' 
  import ModalAddSystems from '@/components/ModalAddToPromocodeSystemRelations.vue'

  const defaultItem = {
    id: null,
    code: "",
    expiry: "",
    promoCodeTypeID: null,
    userID: null
  };
  
  export default defineComponent({
    components: {    
      ModalAddPromoCode,
      ModalAddSystems
    },   
    data() {
      const items = [];

      const columns = [
        { key: 'code', sortable: true , label: 'Промокод'},
        { key: 'expiry', sortable: true, label: 'Дата истечения' },
        { key: 'promoCodeType', sortable: true, label: 'Тип промокода' },
        { key: 'username', sortable: true, label: 'Пользователь'},
        { key: 'actions', width: 80, label: 'Действия' },
      ];
  
      return {
        filter: "",
        items,
        columns,
        editedItemId: null,
        editedItem: null,
        createdItem: { ...defaultItem },
        errors: {},
        successMessage: null,
        promoCodeTypesOptions:[],
        promoCodeTypes: [],
        users:[]
      };
    },
    created() {
        this.fetchData();
        this.loadPromoCodeTypes();
        this.loadUsers();
       document.title = "Промокоды";
    },
    computed: {
      isNewData() {
        return Object.keys(this.createdItem).every(
          (key) => !!this.createdItem[key]
        );
      },      
      transformedItems() {
        return this.items.map(item => {
          const promoCodeType = this.promoCodeTypesOptions.find(
            option => option.value === item.promoCodeTypesID)?.text || 'Unknown';           
          const username = this.users.find(user => user.id === item.userID)?.username || 'Unknown';         
          return {
            ...item,
            promoCodeType: promoCodeType,
            username: username
          };
       });
      },
      hasErrors() {
        return Object.keys(this.errors).length > 0;
      }, 
      customFilterMethod() {      
        return this.filterExact;
      },
    },  
    methods: {
      async loadPromoCodeTypes() {
        try {
          const response = await http.get('/promocodes/types');
          this.promoCodeTypes = response.data.filter(type => type.state === true);         
          this.promoCodeTypesOptions = this.promoCodeTypes.map(promoCodeType => ({
            text: promoCodeType.name,
            value: promoCodeType.id
          })          
          );
          this.promoCodeTypes = this.promoCodeTypesOptions.map(option => option.text);
          
        } catch (error) {
          console.error('Error fetching promo code types:', error);
        }
      },
      async loadUsers() {
        try {
          const response = await http.get('/users/roles/admins-managers');
          this.users = response.data;
        } catch (error) {
          console.error('Error fetching users:', error);
        }
      },
      findPromoCodeTypeIdByText(promoCodeTypeText) {     
        const promoCodeType = this.promoCodeTypesOptions.find(option => option.text === promoCodeTypeText);           
        return promoCodeType ? promoCodeType.value : null;
      }, 
      resetEditedItem() {
        this.editedItem = null;
        this.editedItemId = null;
      },
      resetCreatedItem() {
        this.createdItem = { ...defaultItem };
      },
      getIdByRowIndex(rowIndex) {
        const selectedItem = this.items[rowIndex];
        return selectedItem ? selectedItem.id : null;
      },
      async deleteItemById(id) {
        try {
            const response = await http.delete(`/promocodes/${id}`);
            if (response.status === 200) {
              this.successMessage = 'Тип промокода успешно удален';
              setTimeout(() => {
                this.successMessage = null; 
              }, 5000);
              this.fetchData();
            }
        } catch (error) {
          console.error('Ошибка при удалении типа промокода:', error);
        }
      },      
      validatePromocode(promocode) {
        this.errors = {};
        if (!promocode.code || promocode.code.length > 100 || promocode.code.length <=3) {
          this.errors.name = "Некорректное имя промокода."
        }

        var currentDate = new Date();
        currentDate.setHours(0,0,0,0);
        var expiryDate = new Date(promocode.expiry)
       
        if (!promocode.expiry || expiryDate < currentDate) {
          this.errors.promocodetype = "Дата истечения промокода меньше текущей даты";
        }
        return Object.keys(this.errors).length === 0;
      },   
      async editItem() {
        try { 
          const codeData = {
              id: this.editedItem.id,
              code: this.editedItem.code,
              expiry: this.editedItem.expiry,
              promoCodeTypesID: this.findPromoCodeTypeIdByText(this.editedItem.promoCodeType),             
          };
          const isValid = this.validatePromocode(codeData);

          if (!isValid)
          {
            return;
          }

          const response = await http.put(`/promocodes/${this.editedItem.id}`, codeData);
                    
          if (response.status === 200) {
            this.successMessage = 'Изменения успешно сохранены';            
            setTimeout(() => {
              this.successMessage = null; 
            }, 5000);
            this.fetchData();
          }
        } catch (error) {
          console.error('Error editing item:', error);          
        }
      },
      openModalToEditItemById(id) {
        this.editedItemId = id;
        this.editedItem = { ...this.items[id] };
      },
      async fetchData() {
        try {
            const response = await http.get('/promocodes/with-data');      
            
            this.items = response.data;           
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        },   
      },
      filterExact(source) {
      if (this.filter === "") {
        return true;
      }
      return source?.toString?.().toLowerCase().includes(this.filter.toLowerCase());
    },
  });
  </script>