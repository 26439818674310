import { createStore } from 'vuex';

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}

const store = createStore({
  state: {
    auth: {
      accessToken: '',
      refreshToken: '',
      expiry: '',
      username: '',
      userRole: ''
    }
  },
  mutations: {    
    setAuth(state, authData) {
      state.auth.accessToken = authData.accessToken;
      state.auth.refreshToken = authData.refreshToken;
      state.auth.expiry = authData.expiry;
    },
    clearAuth(state) {
      state.auth.accessToken = '';
      state.auth.refreshToken = '';
      state.auth.expiry = '';
      state.auth.userRole = '';
      state.auth.username = '';
    },
    setUser(state, userData) {
      state.auth.username = userData.username;
      state.auth.userRole = userData.role;
    },
    restoreState(state) {
      const stateFromStorage = localStorage.getItem('authState');
      if (stateFromStorage) {
        Object.assign(state.auth, JSON.parse(stateFromStorage));
      }
    }
  },
  actions: {
    login({ commit }, authData) {     
      commit('setAuth', authData);
      const userData = parseJwt(authData.accessToken);
      commit('setUser', {username: userData.unique_name, role:userData.role});      
      this.dispatch('saveState');
    },
    logout({ commit }) {      
      commit('clearAuth');
      localStorage.removeItem('authState');
      this.dispatch('saveState');
    },
    saveState({ state }) {
      const stateToSave = JSON.stringify(state.auth);
      localStorage.setItem('authState', stateToSave);
    },
    updateTokens({ commit }, tokens) {
      commit('setAuth', tokens);    
      this.dispatch('saveState');  
    },
  },
  getters: {
    isAuthenticated:(state)=> {
      return !!state.auth.accessToken; 
    },
    username: (state) => {
      return state.auth.username;      
    },
    userRole: (state) => {
      return state.auth.userRole;
    },
    accessToken: (state) => {
      return state.auth.accessToken;
    },
    refreshToken:(state) => {
      return state.auth.refreshToken;
    },
    expiry:(state)=> {
      return state.auth.expiry;
    }    
  }
});

store.commit('restoreState');

export default store;
