<template>

  <VaInput
      v-model="filter"
      title="Search"
      placeholder="Поиск системы"      
      clearable
      class="mr-3 grow-0 basis-24"
    />  

  <VaDataTable
    :items="transformedItems"
    :filter="filter"
    :filter-method="customFilterMethod"
    :columns="columns"
    style="height: 80vh" 
    virtual-scroller   
  ></VaDataTable>


</template>

<script>
import { defineComponent } from "vue";
import http from '@/services/http-common';

const defaultItem = {
  id: null,
  name: "",
  description: "",
  state: false
};

export default defineComponent({  
  data() {
    const items = [];

    const columns = [
      {key:'name', sortable:true, label: 'Имя системы'},
      {key:'description', sortable:true, label: 'Описание'},  
      {key:'state', sortable:true, label: 'Состояние'},
      {key:'createdAt', sortable:true, label: 'Дата создания'}
      
    ];

    return {
      filter: "",  
      items,
      columns,
      editedItemId: null,
      editedItem: null,
      createdItem: { ...defaultItem },
      errors: {},
      successMessage: null,
    };
  },
  created() {
    this.fetchData();    
    document.title = "Системы";    
  },
  computed: {
    isNewData() {
      return Object.keys(this.createdItem).every(
        (key) => !!this.createdItem[key]
      );
    },  
    transformedItems() {
      return this.items.map(item => {        
        return {
          ...item,     
          state: item.state == true ? 'активна' : 'неактивна' 
        };
      });
    },
    customFilterMethod() {      
      return this.filterExact;
    },  
  }, 
  methods: {
    resetEditedItem() {
      this.editedItem = null;
      this.editedItemId = null;
    },
    resetCreatedItem() {
      this.createdItem = { ...defaultItem };
    },  
    openModalToEditItemById(id) {
      this.editedItemId = id;
      this.editedItem = { ...this.items[id] };
    },
    async fetchData() {
      try {
        const response = await http.get('/systems');       
        this.items = response.data;
      } catch (error) {
        console.error('Ошибка получения данных:', error);
      }
    }, 
    filterExact(source) {
      if (this.filter === "") {
        return true;
      }
      return source?.toString?.().toLowerCase().includes(this.filter.toLowerCase());
    },      
  },
});
</script>