<template>
    <div class="welcome-page">      
      <p>
        <span>Данный сервис, предназначен для создания, генерации и проверки промокодов. Он обеспечивает пользователей необходимыми инструментами для работы.</span><br/> 
        <span>Краткий обзор каждой страницы:</span>
      </p>
      <ul class="custom-list">
        <li>  <strong>Пользователи :</strong> Позволяет просматривать, редактировать, добавлять и удалять пользователей приложения Промокоды</li>
        <li>  <strong>Промокоды :</strong> Позволяет просматривать, редактировать, добавлять, удалять, генерировать промокоды.</li>
        <li>  <strong>Типы промокодов :</strong> Позволяет просматривать, редактировать, добавлять, удалять типы промокодов.</li>
        <li>  <strong>Системы :</strong> Позволяет пользователю добавить, редактировать, удалить систему работающую с приложением промокоды.</li>
        <li>  <strong>Журнал действий :</strong> На данной странице отображены все события системы.</li>
        <li>  <strong>Информационные панели :</strong> Здесь вы можете получить доступ к панелям и отчетам.</li>        
      </ul>
    </div>
  </template>
  
  <script>
  export default {
    name: 'HelloView',
    created() {
      document.title = "О промокодах";
    }
  }
  </script>
  
  <style scoped>
  .welcome-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 10px;    
  }

  .welcome-page span {
    margin-left: 40px;
  }
  .welcome-page h5 {
    font-size: 20px;
    margin-bottom: 15px;
    text-align: center;
  }
  
  .welcome-page p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  
  .welcome-page .custom-list {
    padding-left: 20px; 
  }

  .welcome-page .custom-list li {
    position: relative;
    list-style-type: none; 
    margin-bottom: 10px;
  }

  .welcome-page .custom-list li::before {
    content: "\2022"; 
    position: absolute;
    left: -20px; 
    color: #48b400c7; 
  }
  </style>
  