<template>

  <VaInput
      v-model="filter"
      title="Search"
      placeholder="Поиск типа промокода"      
      clearable
      class="mr-3 grow-0 basis-24"
    />  

  <ModalAddPromoCodeType @promocode-type-added="fetchData" />

  <VaDataTable
    :items="transformedItems"
    :filter="filter"
    :filter-method="customFilterMethod"
    :columns="columns"
    style="height: 80vh"   
    virtual-scroller   
  > 

    <template #cell(actions)="{ rowIndex }">
      <VaButton
        preset="plain"
        icon="edit"
        @click="openModalToEditItemById(rowIndex)"
      />
      <VaButton
        preset="plain"
        icon="delete"
        class="ml-3"
        @click="deleteItemById(transformedItems[rowIndex].id)"
      />
    </template>
  </VaDataTable>

  <VaModal
    class="modal-crud"
    :model-value="!!editedItem"
    title="Редактирование типа промокода"
    size="small"
    @ok="editItem"
    @cancel="resetEditedItem"
  >
    <template v-for="key in Object.keys(editedItem)" :key="key">
      <template v-if="key === 'id'">
        <VaInput
          v-model="editedItem[key]"
          class="ml-3 form-element"
          label="Id типа промокода"          
          disabled
        />
      </template>
      <template v-else-if="key === 'name'">
        <VaInput
          v-model="editedItem[key]"
          class="ml-3 form-element"
          label="Имя типа промокода"
        />
      </template>
      <template v-else-if="key === 'description'">
        <VaInput
          v-model="editedItem[key]"
          class="ml-3 form-element"
          label="Описание типа промокода"          
        />
      </template>
      <template v-else-if="key === 'state'">
        <VaCheckbox
          v-model="editedItem[key]"
          class="ml-3 form-element"
          label="Состояние - активен / неактивен"
        />
      </template>
      <template v-else>
        <VaInput
          v-model="editedItem[key]"
          class="ml-3 form-element"
          :label="key"
        />
      </template>
    </template>
    <div v-if="hasErrors" class="alert alert-danger mt-3">
      <div v-for="(error, key) in errors" :key="key">{{ error }}</div>
    </div>
    <div v-if="successMessage" class="alert alert-success mt-3">
        {{ successMessage }}
    </div>
  </VaModal>

</template>

<script>
import ModalAddPromoCodeType from '@/components/ModalAddPromoCodeType.vue'
import { defineComponent } from "vue";
import http from '@/services/http-common';

const defaultItem = {
  id: null,
  name: "",
  description: "",
  state: false
};

export default defineComponent({
  components: {    
    ModalAddPromoCodeType
  },
  data() {
    const items = [];

    const columns = [
      {key:'name', sortable: true, label: 'Тип промокода'},
      {key:'description', sortable: true, label: 'Описание'},
      {key:'state', sortable: true, label: 'Состояние'},
      {key:'actions',width: 80, label: 'Действия'}
    ];

    return {
      filter: "",   
      items,
      columns,
      editedItemId: null,
      editedItem: null,
      createdItem: { ...defaultItem },
      errors: {},
      successMessage: null,
    };
  },
  created() {
    this.fetchData();    
    document.title = "Типы промокодов";    
  },
  computed: {
    isNewData() {
      return Object.keys(this.createdItem).every(
        (key) => !!this.createdItem[key]
      );
    },  
    transformedItems() {      
      return this.items.map(item => {        
        return {
          ...item,                 
          state: item.state == true ? 'активен' : 'неактивен'
        };
      });
    },  
    hasErrors() {
      return Object.keys(this.errors).length > 0;
    },    
    customFilterMethod() {      
      return this.filterExact;
    },
  }, 
  methods: {
    resetEditedItem() {
      this.editedItem = null;
      this.editedItemId = null;
    },
    resetCreatedItem() {
      this.createdItem = { ...defaultItem };
    },
    async deleteItemById(id) {
      try {
        const response = await http.delete(`/promocodes/types/${id}`);
        if (response.status === 200) {
          this.successMessage = 'Тип промокода успешно удален';
          setTimeout(() => {
            this.successMessage = null; 
          }, 5000);
          this.fetchData();
        }
      } catch (error) {
        console.error('Ошибка при удалении типа промокода:', error);
      }
    },
    addNewItem() {
      this.items = [...this.items, { ...this.createdItem }];
      this.resetCreatedItem();
    },
    async editItem() {
      try {

        const isValid = this.validatePromocodeType(this.editedItem);

        if (!isValid) {
          return;
        }

        const promocodeType = {
          id: this.editedItem.id,
          name: this.editedItem.name,
          description: this.editedItem.description,
          state: this.editedItem.state
        }
        
        const response = await http.put(`/promocodes/types/${this.editedItem.id}`, promocodeType);

        if (response.status === 200) {
          this.successMessage = 'Изменения успешно сохранены';
          setTimeout(() => {
            this.successMessage = null; 
          }, 5000);
          this.fetchData();
          return;
        }            
      }
      catch (error) {
        console.error('Ошибка обновления типа промокода:', error); 
      }

      this.items = [
        ...this.items.slice(0, this.editedItemId),
        { ...this.editedItem },
        ...this.items.slice(this.editedItemId + 1),
      ];
      this.resetEditedItem();
    },
    openModalToEditItemById(id) {
      this.editedItemId = id;
      this.editedItem = { ...this.items[id] };
    },
    validatePromocodeType(promocodetype) {
      this.errors = {};
      if (!promocodetype.name || promocodetype.name.length > 100 || promocodetype.name.length <=3) {
        this.errors.name = "Некорректное имя типа промокода."
      }
      if (!promocodetype.description || promocodetype.description.length < 3 || promocodetype.description.length > 300) {
        this.errors.promocodetype = "Некорректное описание типа промокода"
      }
      return Object.keys(this.errors).length === 0;
    },
    async fetchData() {
      try {
        const response = await http.get('/promocodes/types');       
        this.items = response.data;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }, 
    filterExact(source) {
      if (this.filter === "") {
        return true;
      }
      return source?.toString?.().toLowerCase().includes(this.filter.toLowerCase());
    },   
  },
});
</script>