  <template>
    <VaLayout 
      style="height: 100vh"
      :left="{ absolute: breakpoints.smDown }"
    >
      <template #top>
        <VaNavbar color="primary" class="py-3">
          <template #left>
            <VaButton :icon="showSidebar ? 'menu_open' : 'menu'" @click="showSidebar = !showSidebar" />
          </template>
          <template #center>
            <VaNavbarItem class="va-h6">
              Управление промокодами            
            </VaNavbarItem>
          </template>
          <template #right>
            <LogoutButton></LogoutButton>
          </template>
        </VaNavbar>
      </template>
  
      <template #left>
        <VaSidebar v-model="showSidebar" width="15rem">
          <VaSidebarItem v-if="isAdministrator" @click="navigateTo('/users')">
            <VaSidebarItemContent>
              <VaIcon name="person" />
              <VaSidebarItemTitle>
                Пользователи
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
          <VaSidebarItem  @click="navigateTo('/promocodes')">
            <VaSidebarItemContent>
              <VaIcon name="code" />
              <VaSidebarItemTitle>
                Промокоды
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
          <VaSidebarItem  @click="navigateTo('/promocode-types')">
            <VaSidebarItemContent>
              <VaIcon name="list" />
              <VaSidebarItemTitle>
                Типы промокодов
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
          <VaSidebarItem  @click="navigateTo('/systems')">
            <VaSidebarItemContent>
              <VaIcon name="apps" />
              <VaSidebarItemTitle>
                Системы
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>
          <VaSidebarItem v-if="isAdministrator" @click="navigateTo('/journal')">
            <VaSidebarItemContent>
              <VaIcon name="preview" />
              <VaSidebarItemTitle>
                Журнал действий
              </VaSidebarItemTitle>
            </VaSidebarItemContent>
          </VaSidebarItem>             
        </VaSidebar>
      </template>
  
      <template #content>
        <main class="p-3">
          <router-view></router-view>
        </main>
      </template>
    </VaLayout>
  </template>


<script>
import { ref, onMounted } from 'vue';
import { useBreakpoint } from 'vuestic-ui';
import LogoutButton from '@/components/LogoutButton.vue';

export default { 
  components: {
    LogoutButton
  },
  computed: {
    currentTab() {
      return this.tabs.find(({ title }) => title === this.value);
    },
    isAdministrator() {      
      const userRole = this.$store.getters.userRole; 
      return userRole === 'administrator';      
    }
  },
  methods: {      
    navigateTo(route) {
      this.$router.push(route);
    }
  },
  setup() {
    const showSidebar = ref(true);
    const breakpoints = useBreakpoint();

    onMounted(() => {

    });

    return { showSidebar, breakpoints };
  },
};
</script>
