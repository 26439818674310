<template>
    <VaButton
            @click="showModal = true"
            preset="plain"
            icon="dataset"
            class="ml-3"
            />

    <VaModal        
        v-model="showModal"
        size="small"
        no-padding
        >

        <template #content="{ ok }">  
            <VaCardContent>
                <div class="checkbox-item">Выберите систему(-ы) для интеграции промокода:</div>
                    <VaCheckbox
                        v-for="item in items"
                        :key="item.systemId"
                        :label="item.systemName"
                        v-model="checkedItems[item.systemId]"
                        @click="handleCheckboxChange(item)"
                        class="checkbox-item"
                    />        
                <VaCardActions>               
                    <VaButton @click="ok">
                        Закрыть
                    </VaButton>
                </VaCardActions>                        
            </VaCardContent>
        </template>
    </VaModal>
</template>

<script>
import http from '@/services/http-common';

export default {
   props: {
    codeId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showModal: false, 
      items: [],
      relations: [],
      checkedItems: {}
    };
  },  
  async created() {    
    try {        
        await Promise.all([this.loadSystems(), this.loadCodeSystemRelations()]);
        
        this.initializeCheckedItems();
    } catch (error) {
        console.error('Ошибка при инициализации данных:', error);
    }
  },
  methods: {   
    initializeCheckedItems() {        
        this.items.forEach(item => {
            this.checkedItems[item.systemId] = this.relations.some(relation => relation.systemId === item.systemId);
        });
    }, 
    async loadSystems() {
      try {
        const response = await http.get('/systems/ids-and-names');             
        this.items = response.data.map(system => ({
            systemId: system.id,
            systemName: system.name
        }));            

      } catch (error) {
        console.error('Ошибка при загрузке списка ролей:', error);
      }
    }, 
    async loadCodeSystemRelations() {
        try {
            const response = await http.get(`/promocodes/system-relations/${this.codeId}`);    

            this.relations = response.data.map(relation => ({
                codeId: relation.codeId,
                systemId: relation.systemId
        }));            

      } catch (error) {
        console.error('Ошибка при загрузке списка ролей:', error);
      }
    },
    closeModal()
    {
      this.showModal = false;
    }, 
    async handleCheckboxChange(item) {       
        const data = 
        {
            "codeId": this.codeId,
            "systemId": item.systemId
        }     

        if (this.checkedItems[item.systemId])
        {
            await this.insertRelation(data)
        }
        else
        {
            await this.deleteRelation(data);
        }       
    },
    async insertRelation(data) {
        try {
            await http.post('/promocodes/system-relations/insert',data);    
        } catch (error) {
            console.error('Ошибка при загрузке списка ролей:', error);
        }
    },
    async deleteRelation(data) {
        try {
            await http.post('/promocodes/system-relations/delete',data);    
        } catch (error) {
            console.error('Ошибка при загрузке списка ролей:', error);
        }
    },
  }
}
</script>

<style scoped>
    .checkbox-item {
        margin: 10px; 
    }
</style>