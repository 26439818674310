import { createRouter, createWebHashHistory } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import UsersView from '../views/UsersView.vue'
import store from '../store'
import AdminLayout from '@/layouts/AdminLayout.vue'
import PromoCodeTypesView from '@/views/PromoCodeTypesView.vue'
import PromoCodesView from '@/views/PromoCodesView.vue'
import HelloView from '@/views/HelloView.vue'
import SystemsView from '@/views/SystemsView.vue'
import JournalView from '@/views/JournalView.vue'

const availableRoles = ['administrator', 'manager'];

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/',
    name: "AdminLayout",
    component: AdminLayout,
    meta: {
      requiresAuth: true,
      role: availableRoles
    },
    children: [
      { path: '/', component: AdminLayout, meta: { requiresAuth: true, role: availableRoles } },
      { path: '/hello', name:'HelloView', component: HelloView, meta: { requiresAuth: true, role: availableRoles } },
      { path:'/users', name:'Users', component: UsersView, meta: { requiresAuth: true, role: ['administrator'] } },
      { path:'/promocodes', name:'promocodes', component: PromoCodesView, meta: { requiresAuth: true, role: availableRoles } },
      { path:'/promocode-types', name:'promocode-types', component: PromoCodeTypesView, meta: { requiresAuth: true, role: availableRoles } },
      { path:'/systems', name: 'systems', component: SystemsView, meta: { requiresAuth: true, role: availableRoles } },
      { path:'/journal', name:'journal', component: JournalView, meta: { requiresAuth: true, role: ['administrator'] } }      
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const userRole = store.state.auth.userRole; 
  const requiredRoles = to.meta.role;

  if (requiresAuth && !store.state.auth.accessToken) {   
    next({ name: 'Login' });
  } else if (requiresAuth && requiredRoles && !requiredRoles.includes(userRole)) { 
    next({ name: 'Login' });
  } else if (to.path === '/' && to.matched.some(record => record.path === '/')) {      
    next({ name: 'HelloView' });
  } else {    
    next();
  }
});

export default router
