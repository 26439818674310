<template>

  <VaInput
      v-model="filter"
      title="Search"
      placeholder="Поиск записи в журнале"      
      clearable
      class="mr-3 grow-0 basis-24"
    />  

  <VaDataTable
    class="table-crud"
    :items="items"
    :columns="columns"
    style="height: 80vh"  
    :filter="filter"
    :filter-method="customFilterMethod"
    virtual-scroller    
  /> 
</template>

<script>
import { defineComponent } from "vue";
import http from '@/services/http-common';


export default defineComponent({
  data() {
    const items = [];

    const columns = [
      {key:'userName', sortable:true, label: 'Пользователь'},
      {key:'message', sortable:true, label: 'Сообщение'},        
      {key:'timeStamp',width: 80, label: 'Время действия'}
    ];

    return {
      items,
      filter: "",
      columns,
      errors:{},    
    };
  },
  created() {
    this.fetchData();
    document.title = "Журнал действий"
  },
  methods: {
    async fetchData() {
      try {
        const response = await http.get('/journal');       
        this.items = response.data;
      } catch (error) {
        console.error('Ошибка получения данных:', error);
      }
    },
    filterExact(source) {
      if (this.filter === "") {
        return true;
      }
      return source?.toString?.().toLowerCase().includes(this.filter.toLowerCase());
    },
  },
  computed: {
    customFilterMethod() {      
      return this.filterExact;
    },
  }
});
</script>